<template>
  <div class="home_view">
    <header>
      <div class="heart">
        <!-- logo -->
        <div class="logo" @click="goHomeFn()">
          <img class="home_logo" :src="settingData.logo" alt="">
          <img class="home_title" src="@/assets/home_title.png" alt="">
        </div>
        <!-- 栏目 -->
        <div class="navbar">
          <ul>
            <li @click="whoFn('homeFlag')">
              <div :class="homeFlag?'active':''">首页</div>
            </li>
            <li>
              <div class="text" @click="whoFn('mouseFlag')" :class="mouseFlag?'active':''">
                我是谁
                <i v-if="!mouseFlag" class="el-icon-arrow-down"></i>
                <i v-if="mouseFlag" class="el-icon-arrow-up"></i>
              </div>
              <div class="who_list" v-if="mouseFlag">
                <div class="who_item" v-for="item in whoList" :key="item.id"
                  :class="{ active: $route.query.id == item.id }" @click="handleNavTab(item.path, item.id)">{{ item.title
                  }}
                </div>
              </div>
            </li>
            <li>
              <div class="text" @click="whoFn('freeFlag')" :class="freeFlag?'active':''">
                免费体验
                <i v-if="!freeFlag" class="el-icon-arrow-down"></i>
                <i v-if="freeFlag" class="el-icon-arrow-up"></i>
              </div>
              <div class="who_list" v-if="freeFlag">
                <div class="who_item" v-for="item in freelist" :key="item.id" :class="{ active: freeactiveId == item.id }"
                  @click="freeFn(item.id, item.class)">{{ item.class==1?'小班':item.class==2?'中班':'大班' }}
                </div>
              </div>
              <!-- <div class="free_list" v-if="freeChildrenFlag">
                <div class="who_item" v-for="item in freechildren" :key="item.id"
                  :class="{ active: $route.path.includes(item.path) }" @click="handlefreeFn(item.path)">{{ item.text }}
                </div>
              </div> -->
            </li>
            <li>
              <div class="text" @click="whoFn('topicFlag')" :class="topicFlag?'active':''">
                本期内容
                <i v-if="!topicFlag" class="el-icon-arrow-down"></i>
                <i v-if="topicFlag" class="el-icon-arrow-up"></i>
              </div>
              <div class="who_list" v-if="topicFlag">
                <div class="who_item" v-for="item in topiclist" :key="item.id"
                  :class="{ active: topicactiveId == item.id }" @click="topicFn(item.id , item.class)">{{ item.class==1?'小班':item.class==2?'中班':'大班' }}
                </div>
              </div>
              <!-- <div class="free_list" v-if="topicChildrenFlag">
                <div class="who_item" v-for="item in topicchildren" :key="item.id"
                  :class="{ active: $route.path.includes(item.path) }" @click="handletopicFn(item.path)">{{ item.text }}
                </div>
              </div> -->
            </li>
          </ul>
        </div>
        <div class="user" >
          <template v-if="$store.state.token">
             <div class="user_name"  @click="goUserFn">{{$store.state.user.nickname}}</div>
             <img class="user_img" src='../assets/user/4.png' @click="goUserFn" v-if="!$store.getters.USER.avatar_url">
             <img class="user_img" :src="$store.state.user.avatar_url" @click="goUserFn" v-else>
          </template>
           <template v-else>
             <div class="user_name" @click="showLoginTo()">请登录</div>
             <img class="user_img" src='../assets/user/4.png' @click="showLoginTo()">
          </template>
        </div>
        <!-- 登录注册按钮 -->
        <!-- <div class="buttons">
          <button v-if="!$store.getters.TOKEN" class="logon-button" @click="$router.push('/logon')">注册</button>
          <button v-if="!$store.getters.TOKEN" class="login-button" @click="$router.push('/login')">登录</button>
          <el-dropdown @command="handleCommand">
            <div class="el-dropdown-link">
              <img v-if="$store.getters.TOKEN && avatar_url != ''" :src="avatar_url" alt="">
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="user" v-if="$store.getters.TOKEN">
            <div class="name">{{ name }}</div>
            <div class="school">{{ school }}</div>
          </div>
        </div> -->
      </div>
    </header>
    <!-- 中心内容 -->
    <div class="main" @click="flag">
      <router-view></router-view>
    </div>
    <!-- 底部 -->
    <div class="footer" @click="flag" :style="{ background: background }">
      <div class="footer_content">
        <div class="top">
          <div class="heart">
            <div class="form_left">
              <div class="mobile">
                <img src="@/assets/mobile_icon.png" alt="">
                <span style="display:inline-block;width: 65%;">{{ settingData.mobile }}</span>
              </div>
              <div class="email">
                <img src="@/assets/email_icon.png" alt="">
                <span>邮箱：{{ settingData.email }}</span>
              </div>
              <div class="address">
                <img src="@/assets/address_icon.png" alt="">
                <span>地址：{{ settingData.address }}</span>
              </div>
            </div>
            <div class="form_code">
              <div class="code">
                <img class="code_img" :src="settingData.app_image" alt="">
                <span>android下载</span>
              </div>
               <div class="code">
                <img class="code_img" :src="settingData.ios_image" alt="">
                <span>ios下载</span>
              </div>
              <div class="code">
                <img class="code_img" :src="settingData.wechat_image" alt="">
                <span>微信小程序</span>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <p class="text" @click="goBeian()" style="cursor: pointer;">
            <!-- <span @click="goAbout(1)">《用户协议》</span><span @click="goAbout(2)">《隐私政策》</span><span @click="goAbout(3)">《知识产权声明》</span>  -->
            {{ settingData.filing }}</p>
          <p>{{ settingData.copyright }} | <a :href="settingData.business_image" target="block">营业执照</a></p>
        </div>
      </div>
    </div>
    
    <!-- 引入登录组件 -->
    <login :centerDialogVisible="showLogin" @close="clsoePop" @nologin="noLogin" @goLogin="goLogin"></login>
    <!-- 未开放遮罩 -->
    <div class="previewMain">
      <el-dialog
        title=""
        :visible.sync="isPreview"
        :fullscreen="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        >
        <!-- <span class="tips">测试中，敬请期待</span> -->
         <el-image
          :src="perviewImg"
          fit="cover"
          ></el-image>
      </el-dialog>
    </div>
    

  </div>
</template>

<script>
import login from '@/components/login.vue'
import { handelWhoList, handelSetting, getProject,handelSendMsg, handelLogin,handelLoginPass,handelRegister,handelEditPass } from '@/api/home'
import { handelUserIndex } from '@/api/user'
export default {
  name: 'home_view',
  components: {
    login
  },
  data() {
    return {
      avatar_url: '',
      school: '',
      name: '',
      homeFlag: true,
      mouseFlag: false,
      freeFlag: false,
      freeChildrenFlag: false,
      topicFlag: false,
      topicChildrenFlag: false,
      whoList: [
        {
          id: 1,
          path: '/who/define',
          text: '百花思维'
        },
        {
          id: 2,
          path: '/who/introduce',
          text: '产品介绍'
        },
        {
          id: 3,
          path: '/who/usage',
          text: '使用建议'
        }
      ],
      freelist: [
        {
          id: 1,
          text: '小班',
        },
        {
          id: 2,
          text: '中班',
        },
        {
          id: 3,
          text: '大班',
        }
      ],
      freechildren: [
        {
          id: 1,
          path: '/current/practice',
          text: '练习题目'
        },
        {
          id: 2,
          path: '/current/explain',
          text: '讲解视频'
        }
      ],
      freeactiveId: 1,
      topiclist: [
        {
          id: 1,
          text: '小班',
        },
        {
          id: 2,
          text: '中班',
        },
        {
          id: 3,
          text: '大班',
        }
      ],
      topicchildren: [
        {
          id: 1,
          path: '/current/practice',
          text: '练习题目'
        },
        {
          id: 2,
          path: '/current/explain',
          text: '讲解视频'
        },
        {
          id: 3,
          path: '/current/review',
          text: '复习测试'
        }
      ],
      topicactiveId: 1,
      background: '',
      settingData: '',
      form: {
            mobile: '',
            code: '',
            code_key: '',
            password: ''
          },
          sendmsg: '获取验证码',
          isSend: true,//获取验证码
          checkFlag: false,//用户协议
          loginPass: false, //默认密码登录
          registerVisble: false,  //注册面板
          editVisble: false, //修改密码
      showLogin: false,
      isPreview: false,
      perviewImg: require('@/assets/ce.png')
    };
  },
  methods: {
    //暂不登录
    noLogin() {
      this.showLogin = false;
    },
    clsoePop() {
      this.showLogin = false;
    },
    //已有账号，立即登录
    goLogin() {
        this.showLogin = true;
    },
    //协议页面
    goAbout(type) {
      this.$router.push({path: '/about',query: {type: type}})
    },
    // 栏目跳转
    handleNavTab(path, id) {
      this.$router.push({
        path: '/who/define',
        query: {
          id: id
        }
      }).catch(err => { })
      this.mouseFlag = false
    },
    goBeian() {
      window.location.href = "https://beian.miit.gov.cn";
    },
    // 我是谁列表
    async whoListOk() {
      try {
        const res = await handelWhoList()
        if (res.code == 0) {
          this.whoList = res.data
        }
      } catch (error) {

      }
    },
    //获取免费体验/本期内容
    async getProjectList() {
        try {
            const res = await getProject()
            if (res.code == 0) {
                this.freelist = res.data.list1;
                this.topiclist = res.data.list2;
            }
        } catch (error) {
            // this.$message.error(error.response.data.msg)
        }
    },
    handlefreeFn(path) {
      this.$router.push({
        path: path,
        query: { id: this.freeactiveId }
      }).catch(err => { })
      this.freeFlag = false
      this.freeChildrenFlag = false
    },
    //免费体验
    freeFn(id, classid) {
      this.freeactiveId = id;
      this.freeFlag = false;
      this.$router.push({
          path: '/topic',
          query: { id: id, type: 1,classid: classid }
      })
    },
    handletopicFn(path) {
      this.$router.push({
        path: path,
        query: { id: this.topicactiveId }
      }).catch(err => { })
      this.topicFlag = false
      this.topicChildrenFlag = false
    },
    topicFn(id , classid) {
      this.topicactiveId = id;
      this.topicFlag = false;
      
      this.$router.push({
          path: '/topic',
          query: { id: id, type: 2,classid: classid }
      })
     
    },
    whoFn(type) {
      if (type == 'mouseFlag') {
        this.mouseFlag = !this.mouseFlag
        this.topicFlag = false
        this.topicChildrenFlag = false
        this.freeFlag = false
        this.freeChildrenFlag = false
        this.homeFlag = false;
      }
      if (type == 'topicFlag') {
        this.topicFlag = !this.topicFlag
        this.mouseFlag = false
        this.freeFlag = false
        this.freeChildrenFlag = false
        this.homeFlag = false;
      }
      if (type == 'freeFlag') {
        this.freeFlag = !this.freeFlag
        this.mouseFlag = false
        this.topicFlag = false
        this.topicChildrenFlag = false
        this.homeFlag = false;
      }
      if( type == 'homeFlag') {
        if(this.$route.path.includes('/home')) {
          this.homeFlag = true;
        }else {
          this.homeFlag = !this.homeFlag;
        }
        this.mouseFlag = false;
        this.freeFlag = false;
        this.topicFlag = false;
        this.topicChildrenFlag = false;
        this.goHomeFn()
      }
    },
    flag() {
      this.mouseFlag = false
      this.freeFlag = false
      this.freeChildrenFlag = false
      this.topicFlag = false
      this.topicChildrenFlag = false
    },
    goHomeFn() {
      this.$router.push('/home').catch(err => { })
    },
    showLoginTo() {
      this.showLogin = true;
    },
    goUserFn() {
      this.$router.push('/user').catch(err => { })
    },
    // 网站配置
    async settingOk() {
      try {
        const res = await handelSetting()
        if (res.code == 0) {
          this.settingData = res.data;   //is_click==1不可点击，==0正常
          this.isPreview = res.data.is_click==1?true:false;
        }else {
          this.isPreview = false;
        }
      } catch (error) {

      }
    },
    // 个人中心
    async userIndexOk() {
      try {
        const res = await handelUserIndex()
        if (res.code == 0) {
          this.$store.commit('setUser', res.data)
        }
      } catch (error) {
        // this.$message.error(error.response.data.msg)
      }
    },
  },
  computed: {},
  created() {
   
    this.freeactiveId = this.$route.query.id
    this.topicactiveId = this.$route.query.id
    if (this.$route.path == '/home') {
      this.background = ''
    } else {
      this.background = '#f4f4f4'
    }
    this.settingOk()
    this.whoListOk()
    this.userIndexOk()
    this.getProjectList()
    
  },
  watch: {
    $route(to, from, next) {
      this.freeactiveId = this.$route.query.id
      this.topicactiveId = this.$route.query.id
      if (this.$route.path == '/home') {
        this.background = '';
        this.homeFlag = true;
      } else {
        this.background = '#f4f4f4'
      }
    },

  },
}
</script>

<style lang="scss" scoped>
@media (max-width:1800px) {
  .home_view {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-content: center;
    flex-direction: column;

    // background: #f4f4f4;
    header {
      width: 100%;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;

      .heart {
        width: 16.8rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
          display: flex;
          align-items: center;

          .home_logo {
            width: 1rem;
            height: .88rem;
          }

          .home_title {
            width: 3.18rem;
            height: .45rem;
            margin-left: .3rem;
          }
        }

        .navbar {
          height: 100%;

          ul {
            display: flex;
            list-style: none;
            height: 100%;

            li {
              // width: 1.6rem;
              padding: 0 .3rem;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: .28rem;
              font-family: PingFang SC;
              font-weight: 400;
              color: #272727;
              cursor: pointer;
              position: relative;

              .active {
                color: #50A800!important;
              }

              .text {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .who_list,
              .free_list {
                position: absolute;
                bottom: -1.8rem;
                background: #FFFFFF;
                z-index: 999;
                width: 1.9rem;
                // height: 2.43rem;
                background: #FFFFFF;
                box-shadow: 0 .04rem .08rem 0 rgba(0, 0, 0, 0.15);
                padding: 0 .16rem;

                .who_item {
                  height: .7rem;
                  border-bottom: rgba(0, 0, 0, 0.07) solid .01rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: .20rem;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.6);

                  &:last-child {
                    border: 0;
                  }

                  &.active {
                    color: #50A800;
                  }
                }
              }

              .free_list {
                position: absolute;
                bottom: -2rem;
                left: 2.4rem;
              }
            }
          }
        }

        .user {
          display: flex;
          align-items: center;
          cursor: pointer;

          .user_name {
            font-size: .28rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
          }

          .user_img {
            width: .68rem;
            height: .68rem;
            border-radius: 50%;
            margin-left: .16rem;
          }
        }
      }
    }

    // 中间内容
    .main {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .footer {
      width: 100%;
      height: 4.3rem;
      background: rgba(252, 245, 226, 0.68);

      .footer_content {
        width: 100%;
        height: 100%;
        background: url('@/assets/footer.png');
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-content: center;
        flex-direction: column;
      }

      .top {
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .heart {
          width: 12.5rem;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .form_left {
          .mobile {
            width: 2.76rem;
            // height: .62rem;
            // padding: 0.1rem 0;
            background: #FFFFFF;
            border-radius: .5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: .4rem;

            img {
              width: .26rem;
              height: .26rem;
              margin-right: .16rem;
            }

            font-size: .24rem;
            font-family: PingFangSC-Medium,
            PingFang SC;
            font-weight: 500;
            color: #66A51E;
          }

          .email {
            margin-top: .28rem;
            margin-bottom: .18rem;
          }

          .email,
          .address {
            display: flex;
            align-items: center;
            font-size: .24rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;

            img {
              width: .3rem;
              height: .3rem;
              margin-right: .12rem;
            }

          }
        }

        .form_code {
          display: flex;
          align-items: center;

          .code {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            margin-right: 1.2rem;

            .code_img {
              width: 1.2rem;
              height: 1.2rem;
            }

            span {
              font-size: .2rem;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              margin-top: .12rem;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .bottom {
        width: 100%;
        height: 1.38rem;
        background: rgba(0, 0, 0, 0.07);
        display: flex;
        justify-content: center;
        // align-content: center;
        flex-direction: column;

        .text {
          margin-bottom: .18rem;
          span {
            cursor: pointer;
          }
        }

        a {
          color: #fff;
          font-size: .2rem;
          font-family: PingFangSC-Regular, PingFang SC;
          text-decoration: none;
          cursor: pointer;
        }

        p {
          font-size: .2rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
        }
      }
    }
  }
  .previewMain {
     ::v-deep  .el-dialog__body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 87%;
    }
    ::v-deep  .el-dialog__body .tips {
      font-size: 32px;
      color: #ff0000;
      font-weight: bold;
    }
    ::v-deep .el-image {
      width: 38%;
    }
  }
 
}

@media (min-width:1800px) {
  .home_view {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-content: center;
    flex-direction: column;

    header {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;

      .heart {
        width: 1680px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
          display: flex;
          align-items: center;

          .home_logo {
            width: 100px;
            height: 88px;
          }

          .home_title {
            width: 318px;
            height: 45px;
            margin-left: 30px;
          }
        }

        .navbar {
          height: 100%;

          ul {
            display: flex;
            list-style: none;
            height: 100%;

            li {
              // width: 160px;
              padding: 0 30px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 28px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #272727;
              cursor: pointer;
              position: relative;

              .active {
                color: #50A800!important;
              }

              .text {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .who_list,
              .free_list {
                position: absolute;
                bottom: -180px;
                background: #FFFFFF;
                z-index: 999;
                width: 190px;
                // height: 243px;
                background: #FFFFFF;
                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
                padding: 0 16px;

                .who_item {
                  height: 70px;
                  border-bottom: rgba(0, 0, 0, 0.07) solid 1px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 20px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.6);

                  &:last-child {
                    border: 0;
                  }

                  &.active {
                    color: #50A800;
                  }
                }
              }

              .free_list {
                position: absolute;
                bottom: -200px;
                left: 240px;
              }
            }
          }
        }

        .user {
          display: flex;
          align-items: center;
          cursor: pointer;

          .user_name {
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
          }

          .user_img {
            width: 68px;
            height: 68px;
            border-radius: 50%;
            margin-left: 16px;
          }
        }
      }
    }

    // 中间内容
    .main {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .footer {
      width: 100%;
      height: 430px;
      background: rgba(252, 245, 226, 0.68);

      .footer_content {
        width: 100%;
        height: 100%;
        background: url('@/assets/footer.png');
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-content: center;
        flex-direction: column;
      }

      .top {
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .heart {
          width: 1250px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .form_left {
          .mobile {
            width: 276px;
            // height: 62px;
            // padding: 10px 0;
            background: #FFFFFF;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;

            img {
              width: 26px;
              height: 26px;
              margin-right: 16px;
            }

            font-size: 24px;
            font-family: PingFangSC-Medium,
            PingFang SC;
            font-weight: 500;
            color: #66A51E;
          }

          .email {
            margin-top: 28px;
            margin-bottom: 18px;
          }

          .email,
          .address {
            display: flex;
            align-items: center;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;

            img {
              width: 30px;
              height: 30px;
              margin-right: 12px;
            }

          }
        }

        .form_code {
          display: flex;
          align-items: center;

          .code {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            margin-right: 120px;

            .code_img {
              width: 120px;
              height: 120px;
            }

            span {
              font-size: 20px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              margin-top: 12px;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .bottom {
        width: 100%;
        height: 138px;
        background: rgba(0, 0, 0, 0.07);
        display: flex;
        justify-content: center;
        // align-content: center;
        flex-direction: column;

        .text {
          margin-bottom: 18px;
          span {
            cursor: pointer;
          }
        }

        a {
          color: #fff;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          text-decoration: none;
          cursor: pointer;
        }

        p {
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          text-align: center;
        }
      }
    }
  }
  .previewMain {
    ::v-deep .el-dialog__body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 87%;
    }
    ::v-deep  .el-dialog__body .tips {
      font-size: 32px;
      color: #ff0000;
      font-weight: bold;
    }
    ::v-deep .el-image {
      width: 38%;
    }
  }
  
        // 轮播图
        ::v-deep .el-carousel {
            width: 100%;
            height: 100%;
        }

        ::v-deep .el-carousel__container {
            width: 100%;
            height: 100% !important;
        }

        .el-carousel__item img {
            width: 100%;
            height: 100%;
        }

        ::v-deep .el-carousel__indicators .el-carousel__button {
            background: #999;
        }
        
        ::v-deep .el-carousel__indicators .is-active .el-carousel__button{
            background: #66A51E;
        }
}
</style>
